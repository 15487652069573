import { Component, computed, OnInit, signal } from '@angular/core';
import { DsBoxModule, DsButtonModule, DsToastService, DsSelectModule, SelectListOption } from '@bmw-ds/components';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry, ColDef, SideBarDef, GridApi, GridReadyEvent, ProcessCellForExportParams } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { TransportOrder } from '../services/transport-order/transport-order.model';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ChangeStatusCellRendererComponent } from '../change-status-cell-renderer/change-status-cell-renderer.component';
import { HintCellRendererComponent } from '../hint-cell-renderer/hint-cell-renderer.component';
import { SupplierTransportOrder } from '../services/transport-order/supplier/supplier-transport-order.service';
import { ServiceProviderService } from '../services/service-provider/service-provider.service';
import { ServiceProvider } from '../services/service-provider/service-provider.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RowGroupingModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  StatusBarModule,
  MenuModule,
  SetFilterModule,
  CsvExportModule
]);

@Component({
  selector: 'app-service-provider',
  standalone: true,
  imports: [
    AgGridAngular,
    DsBoxModule,
    DsButtonModule,
    DsSelectModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './service-provider.component.html',
  styleUrl: './service-provider.component.scss'
})
export class ServiceProviderComponent implements OnInit {
  public gridApi!: GridApi;
  localeText = AG_GRID_LOCALE_DE;

  public selectedProvider = computed(() => {
    const currentProvider = this.supplierTransportOrderService.getCurrentServiceProvider();
    const providers = this.serviceProviderService.data();
    return providers.some(p => p.name === currentProvider) ? currentProvider : null;
  });

  public computedServiceProviders = computed(() => {
    const providers = this.serviceProviderService.data();
    return providers.map((provider: ServiceProvider) => ({
      id: provider.name,
      label: provider.name
    }));
  });

  public transportorder = computed(() => {
    return this.supplierTransportOrderService.data();
  });

  columns = signal<ColDef<TransportOrder>[]>([
    {
      field: 'id',
      headerName: 'ID',
      filter: 'agTextColumnFilter',
      sortable: false,
      valueGetter: (params) => {
        if (params.data?.id) {
          return params.data.id;
        } else {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        }
      }
    },
    {
      field: 'transportTrackable.vehicle.vehicleLicensePlate',
      headerName: 'Fahrzeug',
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      headerName: 'Verspätung',
      filter: 'agTextColumnFilter',
      cellRenderer: HintCellRendererComponent,
      cellRendererParams: {
        dateFieldKey: 'targetTs',
      },
    },
    {
      headerName: 'Restzeit',
      filter: 'agTextColumnFilter',
      sortable: false,
      valueGetter: (params) => {
        if (params.data?.endTs) {
          return '';
        }

        if (params.data?.targetTs) {
          const targetTs = new Date(params.data.targetTs);
          const now = new Date();
          const diff = targetTs.getTime() - now.getTime();
          const diffInMinutes = Math.floor(diff / 60000);
          return diffInMinutes > 0 ? `${diffInMinutes} min` : '0 min';
        }

        return '0 min';
      }
    },
    {
      field: 'sourceFence.fenceName',
      headerName: 'Von',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      field: 'destinationAreal.fenceName',
      headerName: 'Nach',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      field: 'destinationFence.fenceName',
      headerName: 'Nach',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      headerName: 'Standort',
      sortable: false,
    },
    {
      headerName: 'Aktionen',
      cellRenderer: ChangeStatusCellRendererComponent,
    }
  ]);

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: false,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      }
    ]
  };

  constructor(
    private supplierTransportOrderService: SupplierTransportOrder,
    private serviceProviderService: ServiceProviderService,
    private toastService: DsToastService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const providers = await this.serviceProviderService.loadAll();

      if (providers.length > 0) {
        const currentProvider = this.supplierTransportOrderService.getCurrentServiceProvider();
        // Only set initial provider if none is selected
        if (!currentProvider || !providers.some(p => p.name === currentProvider)) {
          this.supplierTransportOrderService.setServiceProvider(providers[0].name);
        }
        await this.supplierTransportOrderService.loadAll();

        this.toastService.pushToast({
          toastText: 'Transportaufträge für Dienstleister geladen',
          tone: 'positive',
        });
      }
    } catch {
      this.toastService.pushToast({
        toastText: 'Fehler beim Laden der Transportaufträge',
        tone: 'critical',
      });
    }
  }

  async onProviderChange(option: SelectListOption | SelectListOption[] | null): Promise<void> {
    if (!option || !('id' in option)) {
      console.error('Invalid option:', option);
      return;
    }

    const newValue = option.id;
    this.supplierTransportOrderService.setServiceProvider(newValue);
    await this.supplierTransportOrderService.loadAll();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public exportGridToCsv(): void {
    if (this.gridApi) {
      this.gridApi.exportDataAsCsv({
        processCellCallback: (params: ProcessCellForExportParams) => {
          if (params && params.node && params.column.getColId() == 'vehicle.pairing') {
            return params.value ? "Verheiratet" : "Nicht Verheiratet"
          }

          return params.value;
        }
      });
    }
  }
}
