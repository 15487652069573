import { signal } from "@angular/core";
import { ZonesService } from "./zones.service";
import { BaseBackendService } from "@bmw-spp/bmw-spp-frontend-common";
import { environment } from "../../../environments/environment";
import { firstValueFrom } from "rxjs";
import { Fence } from "./fence.model";

export class ZonesBackendService extends BaseBackendService<Fence> implements ZonesService {
    
    override dataChangesAreMonitored = signal(false);
    override data = signal<Fence[]>([]);

    protected override getEndpoint(): string {
        return "v1/transport-order/get-fences";
    }

    override async loadAll(): Promise<Fence[]> {
        const url = `${environment.apiUrl}${this.getEndpoint()}`;
        const fences = await firstValueFrom(this.httpClient.get<Fence[]>(url));
        console.log(fences);
        this.data.set(fences);

        return fences;
    }
}
