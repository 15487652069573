import { BaseBackendService } from "@bmw-spp/bmw-spp-frontend-common";
import { environment } from "../../../environments/environment";
import { firstValueFrom } from "rxjs";
import { VehiclesService } from "./vehicles.service";
import {TrackableVehicle} from "./trackable-vehicle.model";

export class VehiclesBackendService extends BaseBackendService<TrackableVehicle> implements VehiclesService {
    public async upsertTransportOrder(transportOrder: TrackableVehicle): Promise<void> {
        const url = `${environment.apiUrl}/${this.getEndpoint()}`;
        await firstValueFrom(this.httpClient.post(url, transportOrder));
    }
    protected override getEndpoint(): string {
        return "v1/transport-order/get-vehicles?site=" + localStorage.getItem('selectedTenant');
    }

    public override async loadAll(): Promise<TrackableVehicle[]> {
        const url = `${environment.apiUrl}${this.getEndpoint()}`;
        const vehicles = await firstValueFrom(this.httpClient.get<TrackableVehicle[]>(url));
        console.log(vehicles);
        this.data.set(vehicles);

        return vehicles;
    }

    protected override applyChangesToData = (change: unknown) => {
        const newVehicle = change as TrackableVehicle;

        const currentData = this.data();
        let found = false;
        for (let i = 0; i < currentData.length; i++) {
            const oldVehicle = currentData[i];
            if (oldVehicle.vehicle.vehicleLicensePlate === newVehicle.vehicle.vehicleLicensePlate) {
                currentData[i] = newVehicle;
                found = true;
                break;
            }
        }

        if(!found) {
            this.data.set([newVehicle, ...currentData]);
        } else {
            this.data.set([...currentData]);
        }
    }
}
