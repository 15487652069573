import { Injectable } from "@angular/core";
import { BaseBackendService } from "@bmw-spp/bmw-spp-frontend-common";
import { TransportOrder } from "../transport-order.model";
import { SupplierTransportOrder } from "./supplier-transport-order.service";

@Injectable()
export class SupplierTransportOrderBackendService extends BaseBackendService<TransportOrder> implements SupplierTransportOrder {
    private currentServiceProvider = '';

    protected override getEndpoint(): string {
        return "v1/transport-order/get-supplier-transport-orders?serviceProvider=" + this.currentServiceProvider;
    }

    protected override getWebsocketEndpoint = () => {
        return "v1/transport-order/get-transport-orders";
    }

    protected override mapBackendToFrontend(item: TransportOrder): TransportOrder {
        return {
            tenantAlias: item?.tenantAlias,
            object: item.object,
            reporter: item?.reporter,
            assignee: item?.assignee,
            serviceProvider: item?.serviceProvider,
            status: item?.status,
            sourceFence: item?.sourceFence,
            destinationAreal: item?.destinationAreal,
            destinationFence: item?.destinationFence,
            startTs: new Date(item.startTs),
            endTs: item.endTs ? new Date(item.endTs) : undefined,
            targetTs: new Date(item.targetTs),
            omloxSyncTs: item?.omloxSyncTs,
            id: item?.id,
            transportTrackable: item.transportTrackable
        };
    }

    protected override applyChangesToData = (change: unknown) => {
        const transportOrder = change as TransportOrder;

        if (transportOrder.serviceProvider.name !== this.currentServiceProvider) {
            return;
        }

        if (transportOrder.id === "undefined" || transportOrder.id === null || transportOrder.id === "") {
            throw new Error("Transport order id is not defined");
        }

        const currentData = this.data();
        let found = false;
        for (let i = 0; i < currentData.length; i++) {
            const oldTransportOrder = currentData[i];
            if (oldTransportOrder.id === transportOrder.id) {
                currentData[i] = transportOrder;
                found = true;
                break;
            }
        }

        if (!found) {
            this.data.set([transportOrder, ...currentData]);
        } else {
            this.data.set([...currentData]);
        }
    }

    getCurrentServiceProvider(): string {
        return this.currentServiceProvider;
    }

    setServiceProvider(serviceProvider: string): void {
        this.currentServiceProvider = serviceProvider;
    }
}
