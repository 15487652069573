import { Component, computed, OnInit, signal, WritableSignal } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry, GridApi, ColDef, GridReadyEvent, SideBarDef } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { TransportOrderService } from '../services/transport-order/transport-order.service';
import { TransportOrder } from '../services/transport-order/transport-order.model';
import { DsBoxModule, DsButtonModule, DsFormsModule, DsModalModule, DsToastService, SelectListOption } from '@bmw-ds/components';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VehiclesService } from '../services/vehicles/vehicles.service';
import { HintCellRendererComponent } from '../hint-cell-renderer/hint-cell-renderer.component';
import { ZonesService } from '../services/zones/zones.service';
import { ServiceProviderService } from '../services/service-provider/service-provider.service';
import { ServiceProvider } from '../services/service-provider/service-provider.model';
import {TrackableVehicle} from "../services/vehicles/trackable-vehicle.model";
import {FenceForTrackableService} from '../services/fence-for-trackable/fence-for-trackable.service';
import {TrackableWithFence} from '../services/fence-for-trackable/fence-for-trackable.model';
import { Fence } from '../services/zones/fence.model';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, ColumnsToolPanelModule, FiltersToolPanelModule, StatusBarModule, MenuModule, SetFilterModule]);

@Component({
  selector: 'app-transport-order',
  standalone: true,
  imports: [
    AgGridAngular,
    CommonModule,
    DsBoxModule,
    DsButtonModule,
    DsFormsModule,
    DsModalModule,
    FormsModule,
  ],
  templateUrl: './transport-order.component.html',
  styleUrl: './transport-order.component.scss'
})
export class TransportOrderComponent implements OnInit {
  public gridApi!: GridApi;
  public showTransportOrderDialog = false;
  localeText = AG_GRID_LOCALE_DE;

  // Form model
  vehicle: TrackableVehicle | undefined = undefined;
  sourceFence: WritableSignal<Fence | undefined> = signal(undefined);
  destinationAreal: WritableSignal<Fence | undefined> = signal(undefined);
  destinationFence: WritableSignal<Fence | undefined> = signal(undefined);
  selectedServiceProvider: ServiceProvider | undefined = undefined;
  duration = 0;
  comment = '';

  columns = signal<ColDef<TransportOrder>[]>([
    {
      field: 'id',
      headerName: 'ID',
      filter: 'agTextColumnFilter',
      sortable: false,
      valueGetter: (params) => {
        if (params.data?.id) {
          return params.data.id;
        } else {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
              v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        }
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      field: 'transportTrackable.vehicle.vehicleLicensePlate',
      headerName: 'Fahrzeug',
      sortable: false,
    },
    {
      headerName: 'Leitzahl',
      sortable: false,
      valueGetter: (params) => {
        return `Leitzahl ${params.node?.rowIndex}`;
      },
    },
    {
      field: 'sourceFence.fenceName',
      headerName: 'Start',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      field: 'destinationAreal.fenceName',
      headerName: 'Zielareal',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      field: 'destinationFence.fenceName',
      headerName: 'Ziel',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      headerName: 'Standort',
      sortable: false,
    },
    {
      headerName: 'Verspätung',
      cellRenderer: HintCellRendererComponent,
      cellRendererParams: {
        dateFieldKey: 'targetTs',
      },
    },
  ]);
  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: false,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      }
    ]
  };

  public computedTransportOrders = computed(() => {
    return this.transportOrderService.data();
  });

  public computedVehicles = computed(() => {
    const vehicles = this.vehicleService.data();
    return vehicles.map((vehicle: TrackableVehicle) => {
      return {
        id: vehicle.vehicle.vehicleLicensePlate,
        label: vehicle.vehicle.vehicleLicensePlate,
      }
    });
  });

  public computedFences = computed(() => {
    return this.zonesService.data().map((fence: Fence) => {
      return {
        id: fence.fenceName,
        label: fence.fenceName,
      }
    });
  });

  public computedServiceProviders = computed(() => {
    return this.serviceProviderService.data();
  });

  public selectedSourceFenceId = computed<string | null>(() => {
    const fence = this.sourceFence();
    return fence ? fence.fenceName : null;
  });

  private fences: TrackableWithFence[] = [];

  constructor(private transportOrderService: TransportOrderService,
    private vehicleService: VehiclesService,
    private toastService: DsToastService,
    private zonesService: ZonesService,
    private serviceProviderService: ServiceProviderService,
    private fencesService: FenceForTrackableService
  ) { }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  async ngOnInit() {
    try {
      const transportOrderPromise = this.transportOrderService.loadAll();
      const vehiclesPromise = this.vehicleService.loadAll();
      const zonesPromise = this.zonesService.loadAll();
      const serviceProviderPromise = this.serviceProviderService.loadAll();
      const fencesPromise = this.fencesService.loadAll();

      await Promise.all([
        transportOrderPromise,
        vehiclesPromise,
        zonesPromise,
        serviceProviderPromise,
        fencesPromise
      ]);

      this.fences = this.fencesService.data();

      this.toastService.pushToast({
        toastText: 'Daten erfolgreich geladen.',
        tone: 'positive',
      });
    } catch {
      this.toastService.pushToast({
        id: 'transport-order-error',
        tone: 'critical',
        toastText: 'Fehler beim Laden. Bitte versuchen Sie es später erneut.'
      });
    }
  }

  public toggleCreateTransportOrderDialog() {
    this.showTransportOrderDialog = !this.showTransportOrderDialog;
  }

  public handleVehicleSelection(item: SelectListOption | SelectListOption[] | null) {
    // Reset sourceAreal when vehicle selection changes
    this.sourceFence.set(undefined);

    if (!item || !('id' in item)) {
      return;
    }

    const foundVehicle = this.findVehicleByLicensePlate(item.id);
    if (!foundVehicle) {
      return;
    }

    this.vehicle = foundVehicle;
    this.setSourceArealFromVehicle(foundVehicle);
  }

  private findVehicleByLicensePlate(licensePlate: string): TrackableVehicle | undefined {
    return this.vehicleService.data().find((vehicle: TrackableVehicle) =>
      vehicle.vehicle.vehicleLicensePlate === licensePlate
    );
  }

  private setSourceArealFromVehicle(vehicle: TrackableVehicle): void {
    try {
      const sourcenameRelationship = vehicle.object.relationships?.find(rel => rel.sourceName);

      if (!sourcenameRelationship) {
        return;
      }

      const matchingFence = this.fences.find(fence => fence.trackableId === sourcenameRelationship.sourceName);
      if (!matchingFence) {
        return;
      }

      const foundAreal = this.findArealByFenceId(matchingFence.fenceId);

      if (foundAreal) {
        this.updateSourceAreal(foundAreal);
      }
    } catch (error) {
      console.error("Error in fence loading process:", error);
    }
  }

  private findArealByFenceId(fenceId: string): Fence | undefined {
    return this.zonesService.data().find((fence: Fence) => fence.object.alias === fenceId);
  }

  private updateSourceAreal(fence: Fence): void {
    this.sourceFence.set(fence);
    const selectEvent: SelectListOption = {
      id: fence.fenceName,
      label: fence.fenceName
    };
    this.handleSourceArealSelection(selectEvent);
  }

  public handleSourceArealSelection(item: SelectListOption | SelectListOption[] | null) {
    if (item && 'id' in item) {
      const foundFence = this.zonesService.data().find((fence: Fence) => fence.fenceName === item.id);
      this.sourceFence.set(foundFence);
    }
  }

  public handleDestinationArealSelection(item: SelectListOption | SelectListOption[] | null) {
    if (item && 'id' in item) {
      const foundFence = this.zonesService.data().find((fence: Fence) => fence.fenceName === item.id);
      this.destinationAreal.set(foundFence);
    }
  }

  public handleDestinationFenceSelection(item: SelectListOption | SelectListOption[] | null) {
    if (item && 'id' in item) {
      const foundFence = this.zonesService.data().find((fence: Fence) => fence.fenceName === item.id);
      this.destinationFence.set(foundFence);
    }
  }

  async onSubmit() {
    try {
      const startTs = new Date();
      const targetTs = new Date();
      targetTs.setMinutes(targetTs.getMinutes() + this.duration);

      await this.transportOrderService.upsertTransportOrder({
        id: '',
        object: {
          alias: "",
          correlationId: "",
          created: "",
          namespace: "",
          relationships: null,
          updated: ""
        },
        transportTrackable: this.vehicle!,
        tenantAlias: localStorage.getItem('selectedTenant') || '',
        reporter: '',
        assignee: '',
        serviceProvider: this.selectedServiceProvider!,
        status: 'OPEN',
        sourceFence: this.sourceFence()!,
        destinationAreal: this.destinationAreal()!,
        destinationFence: this.destinationFence()!,
        startTs: startTs,
        targetTs: targetTs,
        omloxSyncTs: new Date().toUTCString(),
      });
      this.showTransportOrderDialog = false;
      this.toastService.pushToast({
        id: 'transport-order-success',
        tone: 'positive',
        toastText: 'Transportauftrag erfolgreich erstellt.'
      });
    } catch {
      this.toastService.pushToast({
        id: 'transport-order-error',
        tone: 'critical',
        toastText: 'Fehler beim Speichern. Bitte versuchen Sie es später erneut.'
      });
    }
  }
}
