import { Injectable } from "@angular/core";
import { BaseBackendService } from "@bmw-spp/bmw-spp-frontend-common";
import { TransportOrder } from "./transport-order.model";
import { TransportOrderService } from "./transport-order.service";
import { environment } from "../../../environments/environment";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TransportOrderBackendService extends BaseBackendService<TransportOrder> implements TransportOrderService {
    protected override getEndpoint(): string {
        return "v1/transport-order/get-transport-orders";
    }

    protected override getWebsocketEndpoint = () => {
        return "v1/transport-order/get-transport-orders"
    }

    private getCreateEndpoint(): string {
        return "v1/transport-order/create-transport-order";
    }

    public async upsertTransportOrder(transportOrder: TransportOrder): Promise<void> {
        const url = `${environment.apiUrl}${this.getCreateEndpoint()}/`;
        const response = await firstValueFrom(this.httpClient.post<TransportOrder>(url, transportOrder));
        this.data.set([...this.data(), response]);
    }

    public async assignTransportOrder(transportOrderId: string, employeeName: string): Promise<void> {
        const url = `${environment.apiUrl}/v1/transport-order/${transportOrderId}/assign?employeeName=${employeeName}`;
        await firstValueFrom(this.httpClient.post<TransportOrder>(url, {}));

        // Update the local data with the assigned transport order
        const currentData = this.data();
        const updatedData = currentData.map(order =>
            order.id === transportOrderId
                ? { ...order, assignee: employeeName, status: 'ASSIGNED' as const }
                : order
        );
        this.data.set(updatedData);
    }

    protected override mapBackendToFrontend(item: TransportOrder): TransportOrder {
        return {
            tenantAlias: item?.tenantAlias,
            object: item.object,
            reporter: item?.reporter,
            assignee: item?.assignee,
            serviceProvider: item?.serviceProvider,
            status: item?.status,
            sourceFence: item?.sourceFence,
            destinationAreal: item?.destinationAreal,
            destinationFence: item?.destinationFence,
            startTs: new Date(item.startTs),
            endTs: item.endTs ? new Date(item.endTs) : undefined,
            targetTs: new Date(item.targetTs),
            omloxSyncTs: item?.omloxSyncTs,
            id: item?.id,
            transportTrackable: item.transportTrackable
        };
    }

    public async getTransportOrdersForServiceProvider(serviceProviderId: string): Promise<TransportOrder[]> {
        const url = `${environment.apiUrl}${this.getEndpoint()}/get-supplier-transport-orders?serviceProvider=${serviceProviderId}`;
        const transportOrders = await firstValueFrom(this.httpClient.get<TransportOrder[]>(url));
        console.log(transportOrders);
        this.data.set(transportOrders);

        return transportOrders;
    }

    protected override applyChangesToData = (change: unknown) => {
        const transportOrder = change as TransportOrder;
        if (transportOrder.id === "undefined" || transportOrder.id === null || transportOrder.id === "") {
            throw new Error("Transport order id is not defined");
        }

        const currentData = this.data();
        let found = false;
        for (let i = 0; i < currentData.length; i++) {
            const oldTransportOrder = currentData[i];
            if (oldTransportOrder.id === transportOrder.id) {
                currentData[i] = transportOrder;
                found = true;
                break;
            }
        }

        if (!found) {
            this.data.set([transportOrder, ...currentData]);
        } else {
            this.data.set([...currentData]);
        }
    }
}
