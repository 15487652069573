<div class="row mb-4x">
    <div class="col-8">
        <h1>Dienstleister</h1>
    </div>
    <div class="col-2">
        <ds-select
            *ngIf="computedServiceProviders().length > 0"
            label="Dienstleister auswählen"
            [options]="computedServiceProviders()"
            (optionSelected)="onProviderChange($event)"
            [value]="selectedProvider()">
        </ds-select>
    </div>
    <div class="col-2">
        <button ds-button variant="ghost" icon="download" (click)="exportGridToCsv()" aria-label="CSV Export">
            <span></span>
        </button>
    </div>
</div>
<ds-box>
    <ds-box-content>
        <div class="col-12">
            <ag-grid-angular class="ag-theme-density tra-grid"
                [rowData]="transportorder()" [columnDefs]="columns()" domLayout="normal" [sideBar]="sideBar"
                [localeText]="localeText" [rowHeight]="130" [autoSizeStrategy]="{type: 'fitCellContents'}"
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </ds-box-content>
</ds-box>
