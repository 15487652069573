import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { TransportOrder } from '../services/transport-order/transport-order.model';
import { DsButtonModule, DsToastService } from '@bmw-ds/components';
import { TransportOrderService } from '../services/transport-order/transport-order.service';

@Component({
  selector: 'app-change-status-cell-renderer',
  standalone: true,
  imports: [
    DsButtonModule
  ],
  templateUrl: './change-status-cell-renderer.component.html',
  styleUrl: './change-status-cell-renderer.component.scss'
})
export class ChangeStatusCellRendererComponent {
  params?: ICellRendererParams<TransportOrder>;

  constructor(
    private transportOrderService: TransportOrderService,
    private toastService: DsToastService
  ) {}

  agInit(params: ICellRendererParams<TransportOrder>): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  async changeStatus(status: string): Promise<void> {
    if (!this.params?.data?.id) {
      return;
    }

    try {
      if (status === 'accept') {
        // TODO: get employee name from user
        const employeeName = 'John Doe';
        await this.transportOrderService.assignTransportOrder(this.params.data.id, employeeName);
        this.toastService.pushToast({
          toastText: 'Transportauftrag erfolgreich angenommen.',
          tone: 'positive'
        });
      }
    } catch (error) {
      console.log(error);
      this.toastService.pushToast({
        toastText: 'Fehler beim Ändern des Status.',
        tone: 'critical'
      });
    }
  }


}
