import { environment } from '../../../environments/environment';
import { FenceForTrackableService } from './fence-for-trackable.service';
import { TrackableWithFence } from './fence-for-trackable.model';
import { firstValueFrom } from 'rxjs';
import { BaseBackendService } from '@bmw-spp/bmw-spp-frontend-common';

export class FenceForTrackableBackendService extends BaseBackendService<TrackableWithFence> implements FenceForTrackableService {

    protected override getEndpoint(): string {
        return "v1/transport-order/get-fences-for-trackable";
    }

    public override async loadAll(): Promise<TrackableWithFence[]> {
        const url = `${environment.apiUrl}${this.getEndpoint()}`;
        const fences = await firstValueFrom(this.httpClient.get<TrackableWithFence[]>(url));
        this.data.set(fences);

        return fences;
    }

}
