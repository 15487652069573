<div class="row mb-4x">
    <div class="col-10">
        <h1>Transportaufträge</h1>
    </div>
    <div class="col-2">
        <button ds-button (click)="toggleCreateTransportOrderDialog()">Neuer Transportauftrag</button>
    </div>
</div>
<ds-box>
    <ds-box-content>
        <div class="col-12">
            <ag-grid-angular class="ag-theme-density tra-grid" (gridReady)="onGridReady($event)"
                [rowData]="computedTransportOrders()" [columnDefs]="columns()" domLayout="normal" [sideBar]="sideBar"
                [localeText]="localeText">
            </ag-grid-angular>
        </div>
    </ds-box-content>
</ds-box>
@if (this.showTransportOrderDialog){
<ds-modal size="sm" #tom [ds-modal-trigger-for]="tom" [(isModalOpen)]="showTransportOrderDialog">
    <ds-box-header>Neuer Transportauftrag</ds-box-header>
    <ds-box-content>
        <form ds-form (ngSubmit)="onSubmit()" #pairingForm="ngForm">
            <ds-form-field class="mb-4x">
                <label ds-label for="vehicle">Fahrzeug</label>
              <ds-select [options]="computedVehicles()"
                         name="vehicle"
                         (optionSelected)="handleVehicleSelection($event)"
                         required></ds-select>
            </ds-form-field>
            <ds-form-field class="mb-4x">
                <label ds-label for="sourceFence">Quellareal</label>
                <ds-select
                    [options]="computedFences()"
                    id="sourceFence"
                    name="sourceFence"
                    (optionSelected)="handleSourceArealSelection($event)"
                    [value]="selectedSourceFenceId()"
                    required>
                </ds-select>
            </ds-form-field>
            <ds-form-field class="mb-4x">
                <label ds-label for="targetAreal">Zielareal</label>
                <ds-select
                  [options]="computedFences()"
                  name="targetFence"
                  (optionSelected)="handleDestinationArealSelection($event)"
                  required>
                </ds-select>
            </ds-form-field>
                <ds-form-field class="mb-4x">
                    <label ds-label for="targetLocation">Zielzone</label>
                    <ds-select
                        [options]="computedFences()"
                        id="targetLocation"
                        name="targetLocation"
                        (optionSelected)="handleDestinationFenceSelection($event)"
                        required>
                    </ds-select>
                </ds-form-field>
            <ds-form-field>
                <label ds-label for="serviceProvider">Dienstleister</label>
                <ds-radio-group variant="inline" [isBlock]="true">
                    @for(serviceProvider of computedServiceProviders(); track serviceProvider)  {
                        <input ds-input [id]="serviceProvider.id" type="radio" name="serviceProvider"
                            [value]="serviceProvider"
                            [(ngModel)]="selectedServiceProvider"
                            [placeholder]="'Wählen Sie ' + serviceProvider.name + ' als Dienstleister aus'"
                            required />
                        <label ds-label [for]="serviceProvider.id">{{serviceProvider.name}}</label>
                    }
                </ds-radio-group>
            </ds-form-field>
            <ds-form-field class="mb-4x">
                <label ds-label for="duration">Dauer (Minuten)</label>
                <input ds-input name="duration" type="number" [(ngModel)]="duration" placeholder="Geben Sie hier eine Dauer ein" required />
            </ds-form-field>
            <ds-form-field class="mb-4x">
                <label ds-label for="comment">Kommentar</label>
                <input ds-input name="comment" type="text" [(ngModel)]="comment" placeholder="Geben Sie hier ein Kommentar ein" />
            </ds-form-field>
            <div class="d-flex justify-content-end">
                <button ds-button icon="link" type="submit" [disabled]="!pairingForm.valid"
                    class="me-2x">Erstellen</button>
                <button ds-button variant="outline" (click)="toggleCreateTransportOrderDialog()"
                    type="button">Abbrechen</button>
            </div>
        </form>
    </ds-box-content>
</ds-modal>
}
