import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { DsHeaderModule, DsFormFieldModule, DsListItemModule, DsSelectModule, SelectListOption, DsHeaderTagConfiguration, DsNavigationBarModule, DsNavigationItem, DsToastService } from '@bmw-ds/components';
import { environment } from '../environments/environment';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';
import { TenantService } from '@bmw-spp/bmw-spp-frontend-common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    DsHeaderModule,
    DsNavigationBarModule,
    DsListItemModule,
    DsFormFieldModule,
    DsSelectModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Transportaufträge';

  //Tag with environment for header
  environmentTagConfig: Partial<DsHeaderTagConfiguration> = { label: environment.environmentName };
  userInfo = {
    username: 'John Doe',
    department: 'Dept 1',
    profileImg: 'https://www.gravatar.com/avatar/?d=identicon'
  };

  //Navigation configuration
  navigationItems: DsNavigationItem[] = [
    {
      label: 'Transportaufträge',
      routerLink: '/transportorder',
      icon: 'truck',
    },
    {
      label: 'Transportmonitor',
      routerLink: '/transportmonitor',
      icon: 'computer',
    },
    {
      label: 'Dienstleister',
      routerLink: '/service-provider',
      icon: 'service',
    },
  ];
  tenantOptions: SelectListOption[] = [];
  public dsSelectDefaultValue = signal('');

  constructor(private tenantService: TenantService,
              private oidcSecurityService: OidcSecurityService,
              private toastService: DsToastService) {}

  async ngOnInit() {
      await this.tenantService.loadTenants({groups: []});
      const tenants = this.tenantService.availableTenants();
      if (tenants && tenants.length > 0) {
        this.tenantOptions = tenants.map((group: string) => ({
          id: group,
          label: group,
          value: group,
        }));

        this.handleTenantSelection({
          id: localStorage.getItem('selectedTenant') ?? this.tenantOptions[0].id,
          label: localStorage.getItem('selectedTenant') ?? this.tenantOptions[0].id
        })
        this.dsSelectDefaultValue.set(localStorage.getItem('selectedTenant') ?? this.tenantOptions[0].id);
      }

    if (!environment.authEnabled) {
      return;
    }

    try {
      const loginResult: LoginResponse = await firstValueFrom(this.oidcSecurityService.checkAuth());
      if (!loginResult.isAuthenticated) {
        this.oidcSecurityService.authorize();
        return;
      }
    } catch {
      this.toastService.pushToast({
        toastText: 'Anmeldung fehlgeschlagen',
        tone: 'critical',
      });
    }
  }

  private setEnvironmentTag() {
    this.environmentTagConfig = {
      label: `${environment.environmentName} - ${this.tenantService.selectedTenant() ? this.tenantService.selectedTenant() : 'Please select a tenant'}`,
    };
  }

  public handleTenantSelection(event: SelectListOption | SelectListOption[] | null) {
    if (event && !Array.isArray(event)) {
      if (event.id !== this.tenantService.selectedTenant()) {
        this.tenantService.setSelectedTenant(event.id);
      }
      this.setEnvironmentTag();
    }
  }
}
