import { IEnvironment, TenantMockService, TenantService } from "@bmw-spp/bmw-spp-frontend-common";
import { TransportOrderService } from "../app/services/transport-order/transport-order.service";
import { TransportOrderBackendService } from "../app/services/transport-order/transport-order.backend.service";
import { VehiclesService } from "../app/services/vehicles/vehicles.service";
import { VehiclesBackendService } from "../app/services/vehicles/vehicles.backend.services";
import { ZonesService } from "../app/services/zones/zones.service";
import { ZonesBackendService } from "../app/services/zones/zones.backend.service";
import { ServiceProviderService } from "../app/services/service-provider/service-provider.service";
import { ServiceProviderBackendService } from "../app/services/service-provider/service-provider.backend.service";
import { SupplierTransportOrder } from "../app/services/transport-order/supplier/supplier-transport-order.service";
import { SupplierTransportOrderBackendService } from "../app/services/transport-order/supplier/supplier-transport-order.backend.service";
import { FenceForTrackableService } from "../app/services/fence-for-trackable/fence-for-trackable.service";
import { FenceForTrackableBackendService } from "../app/services/fence-for-trackable/fence-for-trackable.backend.service";

export const environment: {notificationDurationMS: number } & IEnvironment = {
    environmentName: 'Dev',
    apiUrl: 'https://tra-backend.c2-cloud.de/tra/',
    wsUrl: 'wss://tra-backend.c2-cloud.de/tra/',
    reconnect: {
        retries: 10,
        delayInSeconds: 5000,
    },
    providers: [
        { provide: TransportOrderService, useClass: TransportOrderBackendService },
        { provide: VehiclesService, useClass: VehiclesBackendService },
        { provide: ZonesService, useClass: ZonesBackendService },
        { provide: ServiceProviderService, useClass: ServiceProviderBackendService },
        { provide: TenantService, useClass: TenantMockService },
        { provide: SupplierTransportOrder, useClass: SupplierTransportOrderBackendService },
        { provide: FenceForTrackableService, useClass: FenceForTrackableBackendService },
    ],
    authEnabled: false,
    clientId: "11e378a0-13ec-4477-8aea-85862e202fc3",
    issuer: "https://auth-i.bmwgroup.net:443/auth/oauth2/realms/root/realms/internetb2xmfaonly",
    secureRouteRootUrl: "https://tra-backend.c2-cloud.de/tra/",
    redirectURL: "https://tra-tra.c2-cloud.de/",
    notificationDurationMS: 5000,
};
